#nullfuenf {
	height: auto;
	padding-top: 0px;


	.Zahltitel-5 {
		background-color: transparent;
		font-family: 'Cardo', serif;
		font-style: italic;
		color: $yellow;
		opacity: 0.4;
		margin-left: -14%;
		position: absolute;
		z-index: 200;

		@include ms-respond(font-size, 7);

	}


		&.akkordeon-open {
		.Zahltitel-5 {
			opacity: 0;
		}

		.mehr {
			display: none;
		}

		.less {
			display: block;
		}
	}

}

.rezept {
	background-color: white;
	align-self: center;
	border: 20px;
	min-height: 90vh;
	background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;

		.mehr {
			display: block;

			span {
				border-bottom: 1px solid black;
				position: relative;
				margin: auto;
			}
			
		}

		.less {
			display: none;
			
			span {
				border-bottom: 1px solid black;
			}
		}



	&> .row{
		min-height: 90vh;
		height: auto;
	
	}

	div[class*="col-"] {
		background-color: white;
		padding-top: 50px;
		padding-bottom: 50px;
 
    	margin: 0px;
    	max-width: 80%;

    	@include media-breakpoint-up(md){
    		max-width: 60%;
    		padding-left: 30px;
    		padding-right: 30px;
    		padding-bottom: 60px;
    	}

    	.Rezeptanleitung {
    		padding-top: 0px;
    		padding-bottom: 30px;

    		h2 {
    			padding-top: 0px;
    			margin-top: 8px;
    		}

    	}

    	p {
		text-align: left;
		min-height: 0vh;
		}
	}
}


.Zutaten {
		margin: 0px;
		padding-left: 0px;


		@include ms-respond(font-size, 0.5);
		@include ms-respond(line-height, 1);


			li {
			text-align: left;
			align-items: left;
			list-style-type: none;
			line-height: 30px;
		}

}

