.mxm-accordion {

	margin-bottom: 80px;

.card {
	border: none;

}

.card-header {
	background-color: transparent;
	border-bottom: 1px solid black;
	border-width: 10%;
	margin: 20px 0px;

	a {
		color: black;
		font-size: 20px;
		margin: 2% 0%;
	}

	:first-child {
		margin-top: 3%;
	}



}

.card-body {
	margin-top: 4%;

	.ware {
		align-items: left;
		margin-top: 35px;
		margin-bottom: 20px;
		

		@include media-breakpoint-up(md){
			margin-bottom: 100px;
			margin-top: 70px;
		}


		@include ms-respond(font-size, 0.8);
		@include ms-respond(line-height, 1);
	}

	.price {
		font-family: 'Cardo', sans-serif;
		font-style: italic;
		font-size: 35px;
		display: inline-block;




		@include ms-respond(font-size, 2.6);
		@include ms-respond(line-height, 1);

	}
}
}




#collapseThree {
	margin-bottom: 200px;
}

.collapse {
	//margin-bottom: 200px;
}

.accordion-img {
	display: inline-block;
	margin-top: 10%;
	width: 360px;

	@include media-breakpoint-up(sm){
		margin-top: -5%;
	}

}

