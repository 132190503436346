.header {

	h1 {
		margin-top: 200px;
	}
}


.header-text {
	margin-top: 20%;
}

.header-img {
	margin-top: 40px;
	margin-right: 40px;




	/* &:hover {

	  -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);
} */



	@include media-breakpoint-up(xs){
		padding-left: 15px;
		padding-right: 15px;
	}
	
	@include media-breakpoint-up(sm){
		padding-left: 15px;
		padding-right: 15px;
	}

	@include media-breakpoint-up(md){
		padding-left: 0px;
		padding-right: 0px;
	}
}

.svg-img {
	width: 18%;
	display: block;
	margin-top: 10px;
	margin-left: 34%;
}

.scroll {
	padding-left: 0px;
		
		@include media-breakpoint-up(md){
		padding-left: 15px;
	}
}