.btn-secondary {
	background-color: transparent;
	display: inline-block;
	color: black;
	border: 0px;
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	padding: 0px;

 &:hover {
 	background-color: transparent; 
 	color: $yellowhover;
 	border: 0px;
 }

 &:focus {
 	background-color: transparent;
 }

}

.show > .btn-secondary.dropdown-toggle {
	background-color: transparent;
	color: $yellowhover;
	border: 0px;

}


.btn-primary {
	background-color: transparent;
	display: inline-block;
	color: black;
	border: 1px solid black;
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	padding: 18px 32px; 
	height: 100%;
	margin-top: 20px;

	@include media-breakpoint-up(md){
			margin-top: 0px;
			
		}


	&:hover {
		background-color:$yellowhover;
		border: 1px solid $yellowhover;
		transition: 0.4s;
	}

	&:active {
		background-color: $yellow;

		:focus {
			background-color: $yellow;
		}
	}
}

	.input-group-btn {
	background-color: white;
	color: black; 



	&:hover {
		color: black;
	}

	svg {
		display: block;
		fill: white;

		&#svg-plus{
			width: 20px;
			margin: auto;
			position: relative;
			fill: white;
			stroke: white;
			color: white;
		}

		&#minus{
			width: 20px;
			margin: auto;
			position: relative;
			fill: white;
			stroke: white;
			color: white;
		}
	}

	button {
		background-color: lightgrey;
		border: none;
		outline: none;
		box-shadow: 6;
		width: 100%;

		.btn-success {
			& :hover {
				background-color: red;
		}
	}
	}


}

#quantity{
	width: 50px;
}