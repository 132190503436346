 #navigation {
 	position: fixed;
 	top: 0;
 	font-family: 'Cardo', serif;
 	z-index: 1000;
 	display: none;
 	height: 100%;
 	width: 100%;

 	 @include media-breakpoint-up(md){
    	display: block;
    	margin-top: 2%;
    	height: auto;
    	margin-left: 2%;

}

 ul {
 	 	
 		margin: auto;
 		height: 100%;
 		margin-top: 30%;
 		padding-left: 0px;


 		@include media-breakpoint-up(md){
 			margin-top: 3%;
 			padding-left: 0%;
 			line-height: 20px;
 		}

.nav-link {
 		&.active {
  		.navigation-rg{
  		text-decoration: line-through;
  	
  }
}
}

 }

 	a {
 		display: block;
 		transition: 0.5s;
		font-size: 30px;
 		line-height: 2em;

 		@include media-breakpoint-up(md){
 			font-size: 15px;
 			line-height: 10px;
 		}
	
 		&:active {
 			text-decoration: line-through;
 		}
 	}

 	.navigation-it {
 		display: inline-block;
 		font-family: 'Cardo', serif;
		font-style: italic;
		font-size: 30px;
 		
 		color: white;


		@include media-breakpoint-up(md){
			color: black;
			font-size: 15px;
 			line-height: 20px;
		}

		&:hover  {
 			text-decoration: line-through;
 			color: $yellowhover;
		}
 	}

 	.navigation-rg {
 		font-family: 'Cardo', serif;
 		font-style: normal;
 		display: inline-block;
 		font-size: 30px;

 		color: white;

 		@include media-breakpoint-up(md){
 			font-size: 15px;
 			line-height: 10px;
 			color: black;
	}
 		&:hover  {
 			text-decoration: line-through;
 			color: $white;

 			 @include media-breakpoint-up(md){
 				color: $yellowhover;
 			 }

 		}
 	}
 }


.nav-link {
	padding:0em;
 }
