html{
	body {
		font-family: 'Lato ', sans-serif;
    position: relative;
		width: 100%;
		margin: 0%;
		padding: 0%;
	}

  main {
    overflow-x: hidden;
  }
}

.backgroundbeige {
	background-color: $backgroundbeige;
	height: 100%;
	padding-bottom: 100px;
}

.backgroundbeige-produktion {
	background-color: $backgroundbeige;
	height: 100%;
	padding-bottom: 0px;
}






.menu-toggler {
  width: 50px;
  height: 50px;
  position: fixed;
  margin-top: 20px;
  margin-left: 20px;
  z-index: 1000; 

  @include media-breakpoint-up(md){
    display: none;
}
}

  .menu-toggler:hover {
    color: #ff9a9b; }
  .menu-toggler > div {
  	width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }

  .menu-toggler .close {
    opacity: 0;
    transition: all .2s;
 
  }
  .menu-toggler .open {
    opacity: 1;
    transition: all .2s; 
    transition-delay: 0.2s;
}

.menu-open {
  .close {
    opacity: 1;
    transition: all .2s;
    transition-delay: 0.2s;
  }

  .open {
    opacity: 0;
    transition: all .2s;
  }
}

@media (max-width: 991px) {
  .menu-open {
    
    .menu-toggler {
    fill: white;
    width: 50px;
    height: 50px;
    position: fixed;
    margin-top: 20px;
    margin-left: 20px;
    z-index: 10000; 
    }

    #navigation {
      display: block;
      background-color: $yellowhover;
      width: 100%;
      height: 100%;
      overflow-y: hidden;

    }
  }
}