
img{
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto;
	transition: 0.3s;

	
}



.section {
	 margin-top: 150px;

	@include  media-breakpoint-up(md){
		margin-top: 0px;
	}
}

.container-fluid {
	padding-left: 0px;
	padding-right: 0px;
	
	@include  media-breakpoint-up(md){
	padding-left: 15px;
	padding-right: 15px;
}}

