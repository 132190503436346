#footer {
	margin: 0px;
	padding-top: 20%;


	@include media-breakpoint-up(md){
		margin: 200px;
		padding-top: 0%;
	}

 a {
 	line-height: 50px;
 	font-family: 'Lato', sans-serif;
 	font-style: normal;
 	border-bottom: 1px solid black;

 	&:hover {
 		color: $yellowhover;
 		border-bottom: 1px solid $yellowhover;
 	}
 }


.form-control {
	margin-top: 20%;
	margin-bottom: 20%;
	border: none;
	border-bottom: 1px solid black;
	border-radius: 0rem;
	color: black;


}

button {
 	margin: auto;
 	background-color: transparent; 
 	border: none;
 	color: black;
 	border: 1px solid black;
 	border-radius: 0px;

 	@include media-breakpoint-up(md){
		margin-left: 10%;
	}

 	&:hover {
 		color: white;
 		background-color: $yellowhover;
 		border: 1px solid $yellowhover;
 	}
 }


.footer-logo {
	font-family: 'Cardo', serif;
	font-style: italic;
	font-size: 30px;
	letter-spacing: 0.3px;
	display: inline-block;
	text-align: center;
 }


hr {
    display: inline-block;
    height: 5px;
    border: 0;
    border-top: 1px solid black;
    margin: 1em 0;
    padding: 0;
}

}

.footer-img {
	display: inline-block;
	width: 65px;
	position: relative;
	align-items: center;
	justify-content: center;
	padding: 20px;
}

	
.social {
		margin: auto;
		align-items: center;
		justify-content: center;
}

.verticalline{
	height: 70px;
	border-left: 1px solid #000000;
}

.footer2 {
	margin-top: 00px;

	@include media-breakpoint-up(md){
		margin-top: 60px;
	}

}

.paragraphfooter {
	margin-top: 30px;
	@include media-breakpoint-up(md){
		margin-top: 0px;
	}
}