h1 {
	font-family: 'Cardo', serif;
	font-style: italic;

	@include ms-respond(font-size, 5);

}

h2 {
	margin-top: 30px;
	margin-bottom: 20px;
	font-family: 'Cardo', serif;
	font-style: italic;
	display: block;
	
	@include ms-respond(font-size, 2.6);

}

h3 {
	margin-top: 20px;
	font-family: 'Lato', sans-serif;
	font-style: normal;

	@include ms-respond(font-size, 2);

}

h4 {
	margin-top: 20px;
	font-family: 'Cardo', serif;
	font-style: italic;
	letter-spacing: 0.5px;
	text-align: left;

	@include ms-respond(font-size, 1.7);
}

h5 {
	margin-top: 20px;
	font-family: 'Cardo', serif;
	font-style: italic;
	letter-spacing: 0.5px;
	text-align: left;

	@include ms-respond(font-size, 1.5);
}


p {
	font-family: 'Lato', sans-serif;
	line-height: 30px;
	letter-spacing: 0.2px;

	@include ms-respond(font-size, 0.8);
	@include ms-respond(line-height, 2.8);

}

a {
	font-family: 'Cardo', serif;
	font-style: italic;
	font-size: 16px;
	letter-spacing: 0.7px;
	color: black;

	@include ms-respond(font-size, 0.8);
	@include ms-respond(line-height, 2.3);

	&:hover {
		color: $yellow;
		text-decoration: none;
		transition: 0.3s;
	}

}

.link {
	font-family: 'Lato', sans-serif;
	font-style: normal;
	font-size: 14px;
	letter-spacing: 0.0px;
	border-bottom: 1px solid black;
	padding-bottom: 3px;

	@include ms-respond(font-size, 0.6);
	@include ms-respond(line-height, 1.5);

		&:hover {
		color: $yellow;
		text-decoration: none;
		transition: 0.3s;
		border-bottom: 1px solid $yellow;

	}


}

quote {
	font-family: 'Cardo', serif;
	font-style: italic;

	letter-spacing: 0.1px;

	@include ms-respond(font-size, 2.4);
	@include ms-respond(line-height, 3.3);
}

.Zahltitel {
	font-family: 'Cardo', serif;
	font-style: italic;
	color: $yellow;
	opacity: 0.4;
	margin-top: 260px;

	@include ms-respond(font-size, 7);

}

ul {
	font-family: 'Lato', sans-serif; 
	list-style-type: bullet;

	@include ms-respond(font-size, 0.8);
	@include ms-respond(line-height, 2.3);

}
