#nullzwei {
	margin-bottom: 200px;

	.nullzwei-img {
		display: inline-block;
		margin-top: -300px;
		width: 350px;
		margin-bottom: 50px;
		visibility: hidden;

		@include media-breakpoint-up(md){
			margin-top: -100px;
			margin-bottom: 0px;
			visibility: visible;
;		}
	
	}

	.nullzwei-img-illu {
		display: inline-block;
		width: 299px;
		
		@include media-breakpoint-up(md){
			width: 100%;
		}

	}

	.nullzwei {
		margin-top: 0px;
		display: inline-block;

	

	p {
		margin-top: 60px;
		@include media-breakpoint-up(md){
			margin-top: 170px;
		}
	}

}
}



.wachstum {
	
	.wachstum-titel {
	font-family: 'Cardo', serif; 
	font-style: italic;
	font-size: 20px;
	margin: 40px;
	}

	a {
		margin-top: 40px;
		border-bottom: 1px solid black;
		display: inline-block;

		&:hover {
			border-bottom: 1px solid $yellowhover;
			transition: 0.3s;
		}
	}


}

