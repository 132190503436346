#svg-plus {
	width: 6%;
	left: 0px;

	@include media-breakpoint-up(md){
		width: 1.5%;
		left: 0px;
	}


}



#svg-minus {
	width: 6%;
	left: 0px;

		@include media-breakpoint-up(md){
		width: 1.5%;
		left: 0px;
	}
}

.svg-pfeil {
	top: 17px;
	margin: auto;
	width: 70px;
	stroke: black;
	fill: black;

	&:hover {
		fill: $yellowhover;
		stroke: $yellowhover;
	}
}