
#nulleins {

	.nulleins-img  {
		margin-bottom: 90px;
		margin-top: 0px;
	





	@include media-breakpoint-up(md){
		//margin-top: 150px;
		margin-bottom: 50px;
		max-width: 350px;
	}
}


.nulleins {
	margin-bottom: 84px;
	margin-top: 50px;



	@include media-breakpoint-up(md){
		margin-top: 90px;
		margin-bottom: 230px;

	}

	p {
		margin-top: 50px;
		@include media-breakpoint-up(md){
			margin-top: 0px;
		}

		&:hover {
			color: $yellowhover;
		}
	}

	a {
		border-bottom: 1px solid black;
		margin-bottom: 20px;
		margin-top: 20px;

		@include media-breakpoint-up(md){
			margin-top: 0px;
			}

		&:hover {
			border-bottom: 1px solid $yellowhover;
			transition: 0.3s;
		}
	}
}

}