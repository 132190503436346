.slider{
	width: 100%;
	height: auto;

	
}

.slick-dots {
	background: none;
	list-style: none;
	display: inline-block;
	border: none;
	border-bottom: 0px;
	padding-left: 0px;
	padding-top: 0px;

	li {
		float: left;
		list-style-type: none;
		margin: 10px;
		border-bottom: 0px;
		color: grey;
	}

}

#slick-slide-control00 {
	color: grey;
	border-bottom: 0px;
}
#slick-slide-control01 {
	color: grey;
	border-bottom: 0px;
}
#slick-slide-control02 {
	color: grey;
	border-bottom: 0px;
}

#slick-slide-control03 {
	color: grey;
	border-bottom: 0px;
}

#slick-slide-control04 {
	color: grey;
	border-bottom: 0px;
}

#slick-slide-control05 {
	color: grey;
	border-bottom: 0px;
}
.slick-track {
	height: auto;
}


button {
	border-width: 0px;
	background: none;
	border-bottom: 1px solid black;
	font-family: 'Lato', sans-serif;
	font-style: normal;
	font-size: 15px;
	padding: 0px;
	display: block;
	float: right;

	@include media-breakpoint-up(md){
		display: inline-block;
	}



}
	.slick-prev .slick-arrow {
	visibility: hidden;

	opacity: 0;
	}

