
#zitat {
	//height: 900px;
}

.zitat-author {
	margin-top: 20px;

}

.teaser {
	background-color: white;
	align-self: center;
	border: 20px;
	min-height: 90vh;
	background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;


	&> .row{
		min-height: 90vh;
		height: auto;
	}
	div[class*="col-"] {
		background-color: white;
		padding-top: 60px;
    	padding-bottom: 30px;
    	padding-left: 30px;
    	padding-right: 30px;

    	margin-top: 50px;

		@include media-breakpoint-up(md){
			padding-top: 90px;
    		padding-bottom: 60px;
    		padding-left: 60px;
    		padding-right: 60px;
    		margin-top: 0px;
		}
	}
}
