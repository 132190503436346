$yellow: #ffe999 ;
$yellowhover: #f7c219;
$backgroundbeige: #fffae6;


/*$modularscale: (
  base: 10px,
  ratio: $golden,
  400px: (
    ratio: $golden,
  ),
  900px: (
    base: 10px,
    ratio: $golden,
  ),
  1200px: (
    base: 10px,
    ratio: $golden,
  ),
);*/

$modularscale: (
  base: 10px,
  ratio: 1.5,
  
  300px: (
    ratio: 1.5,
    ),

  500px: (
    ratio: 1.4,
  ),

  700px: (
    ratio: 1.6,
    ),

  900px: (
    base: 10px,
    ratio: 1.3,
  ),
  1200px: (
    base: 10px,
    ratio: 1.6,
  ),
);

$fa-font-path:  '../fonts/';