
img.wachstum {
		width: 30%;
	}

#nulldrei {

	.nulldrei {
		p {
			margin-top: 40px;
			margin-bottom: 80px;
		}
	}
}



.nummerproduktion {
	font-family: 'Cardo', serif;
	display: inline-block;
	font-style: normal;
	margin-left: 5%;

	@include ms-respond(font-size, 2.9);
	@include ms-respond(line-height, 3.3);


}

.produktion {
	display: block;
	font-family: 'Cardo', serif;
	font-style: italic;
	margin-top: 10%;
	padding: 8%;
	
	@include media-breakpoint-up(md){
		margin-top: 4%;
	}


	@include ms-respond(font-size, 1.5);
	@include ms-respond(line-height, 2.2);

}

.next {
	font-family: 'Lato', sans-serif;
	font-style: normal;
	display: inline-block;
	border-bottom: 1px solid black;
	padding-bottom: 4px;
	text-align: right;
	margin-left: 80%;
	margin-top: 15%;
	margin-bottom: 40px;


	@include media-breakpoint-up(md){
		margin-top: 5%;
	}


	@include ms-respond(font-size, 0.8);
	@include ms-respond(line-height, 1);

	&:hover {	
			color: $yellowhover;
			border-bottom: 1px solid $yellowhover;
			transition: 0.3s;
		
	}
}	

.hrdrei {
	display: inline-block;
	width: 1%;
	background-color: black;
	height: 1px;
}